var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Navbar', {
    attrs: {
      "nav-light": true
    }
  }), _c('section', {
    staticClass: "bg-half bg-primary d-table w-100"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-lg-12 text-center"
  }, [_c('div', {
    staticClass: "page-next-level"
  }, [!_vm.pengesahan ? _c('h4', {
    staticClass: "title text-white"
  }, [_vm._v(" " + _vm._s(_vm.$t("wasiatsidebar.header")) + " ")]) : _vm._e(), _vm.pengesahan ? _c('h4', {
    staticClass: "title text-white"
  }, [_vm._v(" " + _vm._s(_vm.$t("wasiatasas.confirmation")) + " ")]) : _vm._e()])])])])]), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12"
  }, [_vm.wasiat && _vm.wasiat.status == 0 && _vm.wasiat.rejected_reason ? _c('div', {
    staticClass: "alert alert-warning"
  }, [[1, 2].includes(_vm.optionDeleteDisplay) ? _c('h5', {
    staticClass: "text-center"
  }, [_vm._v(_vm._s(_vm.$t("will-incomplete")))]) : _vm._e(), _vm.optionDeleteDisplay == 3 ? _c('h5', {
    staticClass: "text-center"
  }, [_vm._v(_vm._s(_vm.$t("will-has-been-revoked")) + ".")]) : _vm._e(), _vm.optionDeleteDisplay == 4 ? _c('h5', {
    staticClass: "text-center"
  }, [_vm._v(_vm._s(_vm.$t("will-has-been-rejected")))]) : _vm._e(), _c('hr'), _c('p', [_vm._v(_vm._s(_vm.$t("comment-from-awaris")) + ":")]), _c('p', [_vm._v(_vm._s(_vm.wasiat.rejected_reason))]), _c('p', [_vm._v(_vm._s(_vm.$t("update-will")))])]) : _vm._e()])])]), _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_vm.wasiatPart != 10 ? _c('div', {
    staticClass: "col-lg-4 col-md-4 col-12"
  }, [_c('div', {
    staticClass: "sidebar sticky-bar p-4 rounded shadow bg-light"
  }, [_c('div', {
    staticClass: "widget mb-4 pb-4 border-bottom"
  }, [_c('div', {
    staticClass: "widget-search mt-4 mb-0",
    attrs: {
      "id": "jobkeywords"
    }
  }), _c('router-link', {
    staticClass: "btn w-100 mt-1",
    class: _vm.wasiatPart >= 1 ? 'btn-primary' : 'btn-secondary',
    attrs: {
      "disabled": !_vm.part_a,
      "event": _vm.part_a ? 'click' : '',
      "to": {
        name: 'wasiat.part_a',
        query: {
          wasiat_id: _vm.wasiatId,
          pengesahan: _vm.pengesahan
        }
      }
    }
  }, [_c('span', {
    staticClass: "d-block",
    staticStyle: {
      "font-weight": "100",
      "font-size": "8pt"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("part")) + " A ")]), _c('span', {
    staticClass: "d-block"
  }, [_vm._v(_vm._s(_vm.$t("account")))])]), _c('router-link', {
    staticClass: "btn w-100 mt-1",
    class: _vm.wasiatPart >= 2 ? 'btn-primary' : 'btn-secondary',
    attrs: {
      "disabled": !_vm.part_b,
      "event": _vm.part_b ? 'click' : '',
      "to": {
        name: 'wasiat.part_c',
        query: {
          wasiat_id: _vm.wasiatId,
          pengesahan: _vm.pengesahan
        }
      }
    }
  }, [_c('span', {
    staticClass: "d-block",
    staticStyle: {
      "font-weight": "100",
      "font-size": "8pt"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("part")) + " B ")]), _c('span', {
    staticClass: "d-block"
  }, [_vm._v(_vm._s(_vm.$t("navbar.testament")))])]), _c('router-link', {
    staticClass: "btn w-100 mt-1",
    class: _vm.wasiatPart >= 3 ? 'btn-primary' : 'btn-secondary',
    attrs: {
      "disabled": !_vm.part_c,
      "event": _vm.part_c ? 'click' : '',
      "to": {
        name: 'wasiat.part_payment',
        query: {
          wasiat_id: _vm.wasiatId,
          pengesahan: _vm.pengesahan
        }
      }
    }
  }, [_c('span', {
    staticClass: "d-block",
    staticStyle: {
      "font-weight": "100",
      "font-size": "8pt"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("part")) + " C ")]), _c('span', {
    staticClass: "d-block"
  }, [_vm._v(_vm._s(_vm.$t("payment")))])]), _c('router-link', {
    staticClass: "btn w-100 mt-1",
    class: _vm.wasiatPart >= 4 ? 'btn-primary' : 'btn-secondary',
    attrs: {
      "disabled": !_vm.part_d,
      "event": _vm.part_d ? 'click' : '',
      "to": {
        name: 'wasiat.part_b',
        query: {
          wasiat_id: _vm.wasiatId,
          pengesahan: _vm.pengesahan
        }
      }
    }
  }, [_c('span', {
    staticClass: "d-block",
    staticStyle: {
      "font-weight": "100",
      "font-size": "8pt"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("part")) + " D ")]), _c('span', {
    staticClass: "d-block"
  }, [_vm._v("eKYC")])]), _c('router-link', {
    staticClass: "btn w-100 mt-1",
    class: _vm.wasiatPart >= 5 ? 'btn-primary' : 'btn-secondary',
    attrs: {
      "disabled": !_vm.part_e,
      "event": _vm.part_e ? 'click' : '',
      "to": {
        name: 'wasiat.part_d',
        query: {
          wasiat_id: _vm.wasiatId,
          pengesahan: _vm.pengesahan
        }
      }
    }
  }, [_c('span', {
    staticClass: "d-block",
    staticStyle: {
      "font-weight": "100",
      "font-size": "8pt"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("part")) + " E ")]), _c('span', {
    staticClass: "d-block"
  }, [_vm._v(_vm._s(_vm.$t("testator")))])]), _c('router-link', {
    staticClass: "btn w-100 mt-1",
    class: _vm.wasiatPart >= 6 ? 'btn-primary' : 'btn-secondary',
    attrs: {
      "disabled": !_vm.part_f,
      "event": _vm.part_f ? 'click' : '',
      "to": {
        name: 'wasiat.part_e',
        query: {
          wasiat_id: _vm.wasiatId,
          pengesahan: _vm.pengesahan
        }
      }
    }
  }, [_c('span', {
    staticClass: "d-block",
    staticStyle: {
      "font-weight": "100",
      "font-size": "8pt"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("part")) + " F ")]), _c('span', {
    staticClass: "d-block"
  }, [_vm._v(_vm._s(_vm.$t("heir")) + " ")])]), _c('router-link', {
    staticClass: "btn w-100 mt-1",
    class: _vm.wasiatPart >= 7 ? 'btn-primary' : 'btn-secondary',
    attrs: {
      "disabled": !_vm.part_g,
      "event": _vm.part_g ? 'click' : '',
      "to": {
        name: 'wasiat.part_f',
        query: {
          wasiat_id: _vm.wasiatId,
          pengesahan: _vm.pengesahan,
          tekun: _vm.is_tekun
        }
      }
    }
  }, [_c('span', {
    staticClass: "d-block",
    staticStyle: {
      "font-weight": "100",
      "font-size": "8pt"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("part")) + " G ")]), _c('span', {
    staticClass: "d-block"
  }, [_vm._v(_vm._s(_vm.$t("verification")) + " ")])]), !_vm.is_tekun ? _c('router-link', {
    staticClass: "btn w-100 mt-1",
    class: _vm.wasiatPart >= 8 ? 'btn-primary' : 'btn-secondary',
    attrs: {
      "disabled": !_vm.part_h,
      "event": _vm.part_h ? 'click' : '',
      "to": {
        name: 'wasiat.part_g',
        query: {
          wasiat_id: _vm.wasiatId,
          pengesahan: _vm.pengesahan
        }
      }
    }
  }, [_c('span', {
    staticClass: "d-block",
    staticStyle: {
      "font-weight": "100",
      "font-size": "8pt"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("part")) + " H ")]), _c('span', {
    staticClass: "d-block"
  }, [_vm._v(_vm._s(_vm.$t("witness")))])]) : _vm._e(), _vm.is_tekun ? _c('router-link', {
    staticClass: "btn w-100 mt-1",
    class: _vm.wasiatPart >= 9 ? 'btn-primary' : 'btn-secondary',
    attrs: {
      "disabled": !_vm.part_h,
      "event": _vm.part_h ? 'click' : '',
      "to": {
        name: 'wasiat.part_g',
        query: {
          wasiat_id: _vm.wasiatId,
          pengesahan: _vm.pengesahan
        }
      }
    }
  }, [_c('span', {
    staticClass: "d-block",
    staticStyle: {
      "font-weight": "100",
      "font-size": "8pt"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("part")) + " H ")]), _c('span', {
    staticClass: "d-block"
  }, [_vm._v(_vm._s(_vm.$t("document")))])]) : _vm._e()], 1), _vm.wasiat && _vm.wasiat.status == 2 ? _c('div', {
    staticClass: "text-center"
  }, [_c('button', {
    staticClass: "btn btn-info btn-sm",
    on: {
      "click": _vm.onNavigate
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("print-wasiat")) + " ")])]) : _vm._e(), _vm.userRole == 'Staff' && _vm.pengesahan ? _c('hr', {
    staticClass: "primary"
  }) : _vm._e(), _vm.userRole == 'Staff' && _vm.pengesahan && !_vm.is_bmmb ? _c('div', {
    staticClass: "row d-flex justify-content-center"
  }, [_c('button', {
    staticClass: "btn btn-success mr-1",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.approveWasiat
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("confirm")) + " ")]), _c('button', {
    staticClass: "btn btn-danger ml-1",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.cancelWasiat
    }
  }, [_vm._v(_vm._s(_vm.$t("incomplete")))])]) : _vm._e(), _vm.userRole == 'Staff' && _vm.pengesahan && _vm.is_bmmb ? _c('div', {
    staticClass: "row d-flex justify-content-center mt-3"
  }, [_c('button', {
    staticClass: "btn btn-success mr-1",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.approveWasiatBMMB
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("confirm")) + " ")]), _c('button', {
    staticClass: "btn btn-danger ml-1",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.cancelWasiat
    }
  }, [_vm._v(_vm._s(_vm.$t("incomplete")))]), _c('button', {
    staticClass: "btn btn-danger ml-1 mt-2",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.rejectWasiat
    }
  }, [_vm._v(_vm._s(_vm.$t("rejected")))])]) : _vm._e(), _vm.userRole != 'Client' && _vm.wasiat && _vm.wasiat.acknowledged_pdpa && _vm.wasiat.status == 0 ? _c('div', {
    staticClass: "row d-flex justify-content-center"
  }, [_c('button', {
    staticClass: "btn btn-danger ml-1",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.resetPdPa
    }
  }, [_vm._v(_vm._s(_vm.$t("reset")))])]) : _vm._e()])]) : _vm._e(), _c('router-view')], 1)])]), _c('b-modal', {
    ref: "modalCancelWasiat",
    attrs: {
      "title": "Batal Wasiat",
      "ok-title": _vm.$t('save'),
      "cancel-title": _vm.$t('cancel'),
      "no-enforce-focus": true
    },
    on: {
      "ok": _vm.submitModalCancelWasiat,
      "cancel": _vm.hideModalCancelWasiat
    }
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("comment-to-agent")) + ":")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.rejected_reason,
      expression: "form.rejected_reason"
    }],
    staticClass: "form-control",
    attrs: {
      "id": "address",
      "rows": "2"
    },
    domProps: {
      "value": _vm.form.rejected_reason
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "rejected_reason", $event.target.value);
      }
    }
  })])])])])]), _c('b-modal', {
    ref: "modalCancelWasiatNew",
    attrs: {
      "title": "Batal Wasiat",
      "hide-footer": "",
      "no-enforce-focus": true
    }
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('label', {
    staticClass: "radio"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.optionDelete,
      expression: "optionDelete"
    }],
    attrs: {
      "type": "radio",
      "name": "radio1",
      "value": "1"
    },
    domProps: {
      "checked": _vm._q(_vm.optionDelete, "1")
    },
    on: {
      "change": function ($event) {
        _vm.optionDelete = "1";
      }
    }
  }), _vm._v(" " + _vm._s(_vm.$t("cancelled-will-ekyc")) + " ")])])]), _c('hr', {
    staticClass: "primary"
  }), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('label', {
    staticClass: "radio"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.optionDelete,
      expression: "optionDelete"
    }],
    attrs: {
      "type": "radio",
      "name": "radio1",
      "value": "2"
    },
    domProps: {
      "checked": _vm._q(_vm.optionDelete, "2")
    },
    on: {
      "change": function ($event) {
        _vm.optionDelete = "2";
      }
    }
  }), _vm._v(" " + _vm._s(_vm.$t("cancelled-will-info")) + " ")])])]), _c('hr', {
    staticClass: "primary"
  }), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('label', {
    staticClass: "radio"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.optionDelete,
      expression: "optionDelete"
    }],
    attrs: {
      "type": "radio",
      "name": "radio1",
      "value": "3"
    },
    domProps: {
      "checked": _vm._q(_vm.optionDelete, "3")
    },
    on: {
      "change": function ($event) {
        _vm.optionDelete = "3";
      }
    }
  }), _vm._v(" " + _vm._s(_vm.$t("cancelled-will-dummy-data")) + " ")])])]), _c('hr', {
    staticClass: "primary"
  }), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("cancellation-reason")) + ":")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.rejected_reason,
      expression: "form.rejected_reason"
    }],
    staticClass: "form-control",
    attrs: {
      "id": "address",
      "rows": "2"
    },
    domProps: {
      "value": _vm.form.rejected_reason
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "rejected_reason", $event.target.value);
      }
    }
  })])])]), _c('div', {
    staticClass: "row justify-content-center"
  }, [_c('button', {
    staticClass: "btn btn-danger",
    on: {
      "click": _vm.submitModalCancelWasiat
    }
  }, [_vm._v(_vm._s(_vm.$t("cancel1")))])])])]), _c('b-modal', {
    ref: "modalRejectWasiatBMMB",
    attrs: {
      "title": "Tolak Wasiat",
      "hide-footer": "",
      "no-enforce-focus": true
    }
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("reject-basic-will-application")) + " " + _vm._s(_vm.wasiat ? _vm.wasiat.user_detail.name : "-") + " (" + _vm._s(_vm.$t("mykad")) + ": " + _vm._s(_vm.wasiat ? _vm.wasiat.user_detail.ic_number : "-") + ")? ")])])]), _c('hr', {
    staticClass: "primary"
  }), _c('div', {
    staticClass: "row justify-content-center"
  }, [_c('button', {
    staticClass: "btn btn-danger",
    on: {
      "click": _vm.submitModalRejectWasiat
    }
  }, [_vm._v(_vm._s(_vm.$t("rejected1")))])])])]), _c('Footer'), _c('a', {
    directives: [{
      name: "scroll-to",
      rawName: "v-scroll-to",
      value: '#topnav',
      expression: "'#topnav'"
    }],
    staticClass: "btn btn-icon btn-soft-primary back-to-top",
    attrs: {
      "href": "javascript: void(0);",
      "id": "back-to-top"
    }
  }, [_c('arrow-up-icon', {
    staticClass: "icons"
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }